import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import './Login.scss';

interface Props {
  operation: 'login' | 'register';
}

const Login = ({ operation }: Props) => {
  return (
    <div className="login">
      {operation == 'login' ? <LoginForm /> : null}
      {operation == 'register' ? <RegisterForm /> : null}
    </div>
  );
};

export default Login;
