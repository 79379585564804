import Header from './Header';
import Footer from './Footer';
import { useSearchParams } from 'react-router-dom';

const handleVideoError = () => {
    alert('The share code is invalid or has expired.');
};

const ShareForm = () => {

    const [searchParams] = useSearchParams();
    const shareCode = searchParams.get('c');

    const uri = `${process.env.REACT_APP_BACKEND_URI
        }/api/share/watch?c=${shareCode}`;

    return (
        <>
            <Header />
            <div className="main">
                <div className="search_result">
                    <video controls className="video" src={uri} onError={handleVideoError} />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ShareForm;