import Header from './Header';
import Footer from './Footer';
import UploadForm from './UploadForm';
import SearchForm from './SearchForm';

const Dashboard = () => {
  return (
    <>
      <Header />
      <div className="main">
        <UploadForm />
        <SearchForm />
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
