import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import ShareForm from './components/ShareForm';
import AuthProvider from './auth/AuthProvider';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login operation="login" />} />
          <Route path="/register" element={<Login operation="register" />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="/shared" element={<ShareForm/>}/>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
