import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import axios from 'axios';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  register: (
    username: string,
    password: string,
    registrationCode: string
  ) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const checkAuth = async () => {
    try {
      // Attempt to access a protected route or validate the session
      await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api/validate`, {
        withCredentials: true,
      });
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Authentication check failed:', error);
      setIsAuthenticated(false);
    }
  };

  const login = async (username: string, password: string) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/login`,
        { username, password },
        { withCredentials: true } // This ensures cookies are included in the request
      );
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/logout`,
        {},
        { withCredentials: true } // Ensure the logout request carries the cookie
      );
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  };

  const register = async (
    username: string,
    password: string,
    registrationCode: string
  ) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/register`,
        { username, password, registrationCode },
        { withCredentials: true }
      );
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;
