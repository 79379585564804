import React, { useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { useNavigate, Link } from 'react-router-dom';
import Form from './Form';
import Button from './Button';

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login(username, password);
      console.log('Successfull login');
      navigate('/');
    } catch (error) {
      alert('Login failed!');
      console.error(error);
    }
  };

  return (
    <div className="card acrylic">
      <Form className="form" onSubmit={handleSubmit}>
        <h2 className="form_title">Login</h2>

        <input
          className="form_input"
          type="text"
          id="username"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <input
          className="form_input"
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button className="form_button" type="submit">
          Login
        </Button>

        <p className="form_text">
          Don't have an account? <Link to="/register">Register here</Link>
        </p>
      </Form>
    </div>
  );
};

export default LoginForm;
