import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  type: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: () => void;
}

const Button = ({ children, type: buttonType, className, onClick }: Props) => {
  return (
    <button className={className} type={buttonType} onClick={onClick}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  children: 'Button',
  buttonType: 'button',
  // onClick: () => {},
};

export default Button;
