import Button from './Button';
import Form from './Form';
import './UploadForm.scss';

const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const memeUrl = e.currentTarget.url.value;
  let request = { url: memeUrl };

  const backendUri = process.env.REACT_APP_BACKEND_URI;
  if (!backendUri) {
    console.error('Environment variable REACT_APP_BACKEND_URI is not defined');
    return;
  }

  const token = localStorage.getItem('authToken');

  const response = await fetch(`${backendUri}/api/upload`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  console.log(await response.json());
};

const UploadForm = () => {
  const title = 'Upload Meme';

  return (
    <div className="card acrylic">
      <Form className="form upload_form" onSubmit={handleSubmit}>
        <h2 className="form_title">{title}</h2>
        <input
          className="form_input "
          type="text"
          name="url"
          required
          placeholder="Insert URL here..."
        />
        <Button className="form_button" type="submit">
          Upload
        </Button>
      </Form>
    </div>
  );
};

export default UploadForm;
