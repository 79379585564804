import { ReactNode } from 'react';
import './Form.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  onChange?: (e: React.FormEvent<HTMLFormElement>) => void;
}

const Form = ({ children, className, onSubmit, onChange }: Props) => {
  return (
    <form className={className} onSubmit={onSubmit} onChange={onChange}>
      {children}
    </form>
  );
};

Form.defaultProps = {
  children: 'Form',
  className: 'form',
};

export default Form;
