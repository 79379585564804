import React, { useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { useNavigate, Link } from 'react-router-dom';
import Form from './Form';
import Button from './Button';

const RegisterForm: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [registrationCode, setRegistrationCode] = useState('');
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await register(username, password, registrationCode);
      console.log('Successfully registered');
      navigate('/login'); // Redirect to login page after successful registration
    } catch (error) {
      alert('Registration failed!');
      console.error(error);
    }
  };

  // TODO: client side validation/sanitation of username and password input
  return (
    <div className="card acrylic">
      <Form className="form" onSubmit={handleSubmit}>
        <h2 className="form_title">Register</h2>
        <input
          className="form_input"
          type="text"
          id="username"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="form_input"
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="form_input"
          type="text"
          id="registrationCode"
          placeholder="Registration Code"
          value={registrationCode}
          onChange={(e) => setRegistrationCode(e.target.value)}
        />
        <Button className="form_button" type="submit">
          Register
        </Button>

        <p className="form_text">
          Already have an account? <Link to="/login">Login here</Link>
        </p>
      </Form>
    </div>
  );
};

export default RegisterForm;
