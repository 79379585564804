import { useState } from 'react';
import Button from './Button';
import { match } from 'assert';
import ReactPlayer from 'react-player';
import Form from './Form';
import './SearchForm.scss';

interface SearchRequest {
  search: string;
}

interface Meme {
  'file-id': string;
  'file-type': string;
  'file-format': string;
  'file-location': string;
  'media-text': string;
  'media-language': string;
  'media-content': string;
  'media-resolution': string;
}

interface ShareButtonProperties {
  fileId: string;
}

const ShareButton: React.FC<ShareButtonProperties> = ({ fileId }) => {
  
  const handleShare = async () => {
      let request = { file_id: fileId };

      const backendUri = process.env.REACT_APP_BACKEND_URI;
      if (!backendUri) {
        console.error('Environment variable REACT_APP_BACKEND_URI is not defined');
        return;
      }

      const response = await fetch(`${backendUri}/api/share/create-code`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.shareCode) {
          alert(`https://memevault.varsiuslp.de/shared?c=${responseData.shareCode}`);
      } else {
          throw new Error('Share code was not received in the response');
      }
  };

  return (
      <Button className="form_button" type="button" onClick={handleShare}>
          Share
      </Button>
  );
};

const SearchForm = () => {
  const title = 'Search Meme';
  const [memes, setMemes] = useState<Meme[]>([]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const search = e.currentTarget.search.value;

    const backendUri = process.env.REACT_APP_BACKEND_URI;
    if (!backendUri) {
      console.error(
        'Environment variable REACT_APP_BACKEND_URI is not defined'
      );
      return;
    }

    const queryParams = new URLSearchParams();
    queryParams.append('q', search);

    const response = await fetch(
      `${backendUri}/api/search?${queryParams.toString()}`,
      {
        method: 'GET',
        credentials: 'include',
      }
    );

    setMemes((await response.json()) as Meme[]);
  };

  return (
    <div className="card acrylic">
      <Form className="form search_form" onSubmit={handleSubmit}>
        <h2 className="form_title">{title}</h2>
        <input
          className="form_input"
          type="text"
          name="search"
          placeholder="Search..."
          required
        />
        <Button className="form_button" type="submit">
          Search
        </Button>
      </Form>

      <div className="search_results">
        {memes.map((meme) => {
          const queryParams = new URLSearchParams();
          queryParams.append('id', meme['file-id']);
          queryParams.append('format', meme['file-format']);
          const uri = `${
            process.env.REACT_APP_BACKEND_URI
          }/api/video?${queryParams.toString()}`;

          return (
            <div className="search_result">
              {meme['file-type'] == 'video' && (
                <video controls className="video" src={uri} />
              )}
              {meme['file-type'] == 'image' && (
                <img className="image" src={uri} alt={meme['media-text']} />
              )}
              <p className="search_result_text">{meme['media-text']}</p>
              <ShareButton fileId={meme['file-id']} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchForm;
