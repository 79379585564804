const Header = () => {
  return (
    <header className="header">
      <h1 className="header-title">MemeVault</h1>
      <p className="header-subtitle">Your Ultimate Meme Repository</p>
    </header>
  );
};

export default Header;
